import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

export const TableOfContentsDropdown = styled.div`
  grid-column: 2 / 13;
  grid-row: 1;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 6;
  }
`
