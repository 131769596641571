import React from 'react'
import * as Styled from './styles/LandingPageHero.styles'
import {
  HeroGraphicDisease,
  HeroGraphicCommunity,
  HeroGraphicFoundation,
  HeroGraphicAdvance,
  HeroGraphicResearch,
} from '../Icons'
import TableOfContentsDropdown from './TableOfContentsDropdown'

const LandingPageHero = ({
  pageTitle,
  heroText,
  heroImage,
  heroGraphic,
  uid,
}) => (
  <Styled.LandingPageHero>
    <Styled.HeroGraphicContainer>
      {
        {
          'Disease / Treatment': <HeroGraphicDisease />,
          'Community / Support': <HeroGraphicCommunity />,
          'Foundation / About Us': <HeroGraphicFoundation />,
          'Research / Medical': <HeroGraphicResearch />,
          'Advance Online': <HeroGraphicAdvance />,
        }[heroGraphic]
      }
    </Styled.HeroGraphicContainer>
    {heroImage?.url && (
      <Styled.HeroImage src={heroImage.url} alt={heroImage.alt} />
    )}
    <TableOfContentsDropdown uid={uid} />
    <Styled.TextRegion>
      <Styled.PageTitle>{pageTitle}</Styled.PageTitle>
      <Styled.HeroText>{heroText}</Styled.HeroText>
    </Styled.TextRegion>
  </Styled.LandingPageHero>
)

export default LandingPageHero
