import React from 'react'
import { string } from 'prop-types'
import { graphql, StaticQuery, navigate } from 'gatsby'
import Select, { components } from 'react-select'

import { DROPDOWN_STYLES } from '../../util/constants'
import * as Styled from './styles/TableOfContentsDropdown.styles'

const navQuery = graphql`
  {
    prismicNavigationMenu {
      id
      type
      data {
        body {
          ... on PrismicNavigationMenuDataBodySection {
            id
            slice_label
            slice_type
            primary {
              external_link_text
              parent {
                uid
                type
                link_type
                id
                url
                document {
                  ... on PrismicLandingPage {
                    data {
                      page_title {
                        text
                      }
                    }
                  }
                  ... on PrismicAdvanceLandingPage {
                    data {
                      page_title {
                        text
                      }
                    }
                  }
                }
              }
            }
            items {
              external_link_text
              subpage {
                uid
                type
                link_type
                id
                url
                document {
                  ... on PrismicSubpage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicCmeMediaLibraryPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicMediaLibraryPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicHcpdLandingPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicProductLandingPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicAudienceCampaignPage {
                    data {
                      title: hero_text {
                        text
                      }
                    }
                  }
                  ... on PrismicMediaLibraryItem {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicHcpdNewRegistrationPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicAdvanceArticle {
                    data {
                      title: header {
                        text
                      }
                    }
                  }
                  ... on PrismicStaffBoardLandingPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    data {
                      title: name {
                        text
                      }
                    }
                  }
                  ... on PrismicEventsLandingPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const EllipsisVertical = () => (
  <svg
    width="6"
    height="24"
    viewBox="0 0 6 24"
    fill="#101F73"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="3" r="3" />
    <circle cx="3" cy="12" r="3" />
    <circle cx="3" cy="21" r="3" />
  </svg>
)

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <EllipsisVertical />
  </components.DropdownIndicator>
)

const TableOfContentsDropdown = ({ uid }) => (
  <StaticQuery
    query={`${navQuery}`}
    render={data => {
      const section = data.prismicNavigationMenu.data.body
        .filter(body => body.primary.parent.uid === uid)
        .slice(0, 1)
        .pop()
      if (!section) {
        return null
      }
      const options = section.items.filter(item => {
        if (item.subpage?.uid) {
          return item
        }
      })
      const filteredOptions = options.map(item => {
        const obj =
          item.subpage.uid == 'new-registration'
            ? {
                value: 'health-care-provider-directory/' + item.subpage.uid,
                label: item.subpage.document.data.title.text,
              }
            : {
                value: item.subpage.uid,
                label: item.subpage.document.data.title.text,
              }
        return obj
      })
      return (
        <Styled.TableOfContentsDropdown>
          {options.length > 1 && (
            <Select
              components={{ DropdownIndicator }}
              styles={DROPDOWN_STYLES}
              placeholder="Table of Contents"
              // menuIsOpen={true}
              options={filteredOptions}
              onChange={e => {
                navigate(`/${e.value}/`)
              }}
            />
          )}
        </Styled.TableOfContentsDropdown>
      )
    }}
  />
)

TableOfContentsDropdown.propTypes = {
  uid: string,
}

export default TableOfContentsDropdown
